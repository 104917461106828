<template>
  <div class="mr-3">
    <v-menu
      transition="scale-transition"
      class="cart"
      min-width="300"
      max-width="300"
      offset-y
      light
    >
      <template v-slot:activator="{ on }">
        <v-btn class="cart-btn no-border" color="white" tile outlined v-on="on">
          <template v-if="userCart.length > 0">
            <v-badge right large color="accent">
              <span slot="badge">{{ userCart.length }}</span>
              <v-icon large>shopping_cart</v-icon>
            </v-badge>
          </template>
          <template v-if="userCart.length == 0">
            <v-icon large>shopping_cart</v-icon>
          </template>
        </v-btn>
      </template>
      <div v-if="userCart && userCart.length > 0" class="cart-menu-list white">
        <emb-perfect-scrollbar class="scroll-area" style="max-height: 280px">
          <v-list
            class="cart-list-items py-0"
            v-for="(cart_item, index) in userCart"
            :key="index"
          >
            <v-layout row wrap align-center cart-item ma-0>
              <v-flex sm12 md12 lg12 xl12 pa-0>
                <v-layout row wrap align-center class="ma-0">
                  <v-flex xs3 sm3 md3 lg3 xl3 pa-0>
                    <img
                      :src="
                        lotteries_info.lotteries[cart_item.ticket.type_name]
                          .image
                      "
                      width="60"
                      height="70"
                    />
                  </v-flex>
                  <v-flex xs9 sm9 md9 lg9 xl9 pa-0>
                    <div class="pl-1">
                      <h6 class="word-wrap-break" v-if="coupon_info==null">
                        {{
                          lotteries_info.lotteries[cart_item.ticket.type_name]
                            .name
                        }}
                        <span class="float-right">
                          {{
                            $formatMoney(
                              getTicketSubTotalPrice({
                                ticket: cart_item.ticket,
                                with_discount: true,
                                coupon: coupon_info,
                              })
                            )
                          }}
                        </span>
                      </h6>
                      <h6 class="word-wrap-break" v-else>
                        {{
                          lotteries_info.lotteries[cart_item.ticket.type_name]
                            .name
                        }}
                        <span class="float-right text-decoration-line-through red--text">
                          {{
                            $formatMoney(
                              getTicketSubTotalPrice({
                                ticket: cart_item.ticket,
                                with_discount: true,
                              })
                            )
                          }}
                        </span>
                        <span class="float-right">
                          {{
                            $formatMoney(
                              getTicketSubTotalPrice({
                                ticket: cart_item.ticket,
                                with_discount: true,
                                coupon: coupon_info,
                              })
                            )
                          }}
                        </span>
                      </h6>                      
                      <span>
                        <!-- <emb-currency-sign></emb-currency-sign>{{cart.price * cart.quantity}}-->
                        {{ ticketShortDescription(cart_item.ticket) }}
                      </span>
                    </div>
                  </v-flex>
                  <v-flex sm4 md4 lg4 xl4 text-center pa-0 cart-action>
                    <v-btn
                      class="primary d-inline-block"
                      icon
                      @click.stop="dialog = true"
                      @click="deleteProductFromCart(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      class="
                        primary
                        d-inline-block
                        ml-2
                        d-inline-flex
                        align-items-center
                      "
                      icon
                      :to="'/cart/' + index.toString()"
                      v-if="cart_item.ticket.quickPicks == undefined"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-list>
        </emb-perfect-scrollbar>
        <emb-delete-confirmation
          ref="deleteConfirmationDialog"
          :message="this.$t('message.dialogs.areYourSure')"
          @onConfirm="onDeleteProductFromCart"
        >
        </emb-delete-confirmation>
        <v-layout align-center pa-3>
          <v-btn class="accent white--text" block @click="checkout()">{{
            $t("message.billing.checkout")
          }}</v-btn>
        </v-layout>
      </div>
      <div v-else class="text-center white pa-6">
        <v-icon size="31" class="accent--text"> shopping_cart </v-icon>
        <h5>{{ $t("message.billing.noProduct") }}</h5>
      </div>
    </v-menu>
  </div>
</template>

<style scoped>
.no-border {
  border: thin solid #00000000;
}
</style>

<script>
import { mapGetters } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    embPerfectScrollbar: VuePerfectScrollbar,
  },
  data() {
    return {
      userCart: [],
      selectDeletedProductIndex: -1,
      settings: {
        maxScrollbarLength: 160,
      },
    };
  },
  computed: {
    ...mapGetters([
      "cart",
      "cart_tickets",
      "selectedCurrency",
      "currencies",
      "getUser",
      "lotteries_info",
      "getTicketSubTotalPrice",
      "coupon_info"
    ]),
  },
  watch: {
    "$store.state.cart_tickets": function () {
      this.userCart = this.$store.state.cart_tickets;
    },
  },
  methods: {
    checkout() {
      this.userCart = this.$store.state.cart_tickets;
      if (this.getUser == null) {
        this.$router.push("/checkout");
      } else {
        this.$router.push("/checkout/payment");
      }
    },
    deleteProductFromCart(cart_index) {
      this.$refs.deleteConfirmationDialog.openDialog();
      this.selectDeletedProductIndex = cart_index;
    },
    onDeleteProductFromCart() {
      this.$refs.deleteConfirmationDialog.close();
      this.$snotify.success(this.$t("message.dialogs.productRemoving"), {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 1000,
      });
      this.$store.dispatch("remove_from_cart", this.selectDeletedProductIndex);
    },
    ticketShortDescription(ticket) {
      if (ticket.quickPicks != undefined && ticket.quickPicks > 0) {
        return "(" + ticket.quickPicks.toString() + " Quickpicks)";
      } else {
        return "(" + ticket.grids.length.toString() + " grids)";
      }
    },
  },
};
</script>