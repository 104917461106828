
import { languages, languages_fr, currencies } from "./data";

const state = {
	languages,
	languages_fr,
	selectedLocale: languages[0],
	languageInitialized: false,
	currencies,
	selectedCurrency: currencies[0],
	rtlLayout: false

}

// getters
const getters = {
	languages: state => {
		return state.selectedLocale.locale == "en" ? state.languages : state.languages_fr;
	},
	selectedLocale: state => {
		// The first time, the language is set with the broswer language
		var userLang = navigator.language || navigator.userLanguage;
		if (state.languageInitialized == false) {
			var result = state.languages.filter(language => (language.locale === userLang));
			if (result.length > 0) {
				state.selectedLocale = result[0];
			}
			state.languageInitialized = true;
		}
		return state.selectedLocale;
	},
	currencies: state => {
		return state.currencies;
	},
	selectedCurrency: state => {
		return state.selectedCurrency;
	},
	rtlLayout: state => {
		return state.rtlLayout;
	}
}

// actions
const actions = {
	changeLanguage(context, payload) {
		context.commit('changeLanguageHandler', payload);
	},
	changeCurrency(context, payload) {
		if (payload != undefined) {
			context.commit('changeCurrency', payload);
		}
	},
	changeRtlLayout(context) {
		context.commit('rtlLayoutHandler');
	}
}

// mutations
const mutations = {
	/**
	* method for setting language state
   */
	changeLanguageHandler(state, language) {
		state.selectedLocale = language;
	},
	/**
	* method for setting currency
   */
	changeCurrency(state, currencies) {
		state.selectedCurrency = currencies;
	},
	/**
	* method for setting rtl layout
   */
	rtlLayoutHandler(state) {
		state.rtlLayout = !state.rtlLayout;
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
