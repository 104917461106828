import Admin from 'Container/Adminpanel.vue';

const Reports = () => import('Views/AdminPanel/Reports.vue');
const SellsReports = () => import('Views/AdminPanel/SellsReports.vue');
const Stats = () => import('Views/AdminPanel/Stats.vue');
// const Invoice = () => import('Views/AdminPanel/Invoices.vue');
const AdminAccount = () => import('Views/AdminPanel/Account.vue');
const CustomerAdminAccount = () => import('Views/AdminPanel/CustomerAccount.vue');
const CustomerOrderHistory = () => import('Views/AdminPanel/CustomerOrderHistory.vue');
const CustomerHistory = () => import('Views/AdminPanel/CustomerHistory.vue');
const CustomerProfile = () => import('Views/AdminPanel/CustomerProfile.vue');
const CustomerAction = () => import('Views/AdminPanel/CustomerAction.vue');
const CustomerCart = () => import('Views/AdminPanel/CustomerCart.vue');
const Collaboration = () => import('Views/AdminPanel/Collaboration.vue');
const AccountSetting = () => import('Views/AdminPanel/AccountSetting.vue');
const AdminProfile = () => import('Views/AdminPanel/Profile.vue');
const AdminEditProfileInfo = () => import('Views/AdminPanel/EditProfileInfo.vue');
// const AdminProducts = () => import('Views/AdminPanel/Products.vue');
// const AdminProductsAdd = () => import('Views/AdminPanel/ProductsAdd.vue');
// const ProductsEdit = () => import('Views/AdminPanel/ProductsEdit.vue');
const PrivacyPolicy = () => import('Views/AdminPanel/ProductsEdit.vue');
const Camera = () => import('Views/AdminPanel/CameraView.vue');
const ScanBatch = () => import('Views/AdminPanel/ScanBatch.vue');
const ScanWithScanner = () => import('Views/AdminPanel/ScanWithScanner.vue');
const ScanBatchSelectCustomer = () => import('Views/AdminPanel/ScanBatchSelectCustomer.vue');
const AdminOrder = () => import('Views/AdminPanel/OrderView.vue');
const SearchUserInfo = () => import('Views/AdminPanel/SearchUserInfo.vue');
const AdminSettings = () => import('Views/AdminPanel/AdminSettings.vue');
const AdminTools = () => import('Views/AdminPanel/AdminTools.vue');
export default {
	path: '/admin-panel',
	component: Admin,
	redirect: '/admin-panel/reports',
	children: [
		{
			path: 'reports',
			component: Reports,
			name: 'Orders',
			meta: {
				showChat: false
			}
		},
		{
			path: 'stats',
			component: Stats,
			name: 'Reports',
			meta: {
				showChat: false
			}
		},
		{
			path: 'search-user-info',
			component: SearchUserInfo,
			name: 'User Info',
			meta: {
				showChat: false
			}
		},
		{
			path: 'admin-settings',
			component: AdminSettings,
			name: 'Settings',
			meta: {
				showChat: false
			}
		},
		{
			path: 'admin-tools',
			component: AdminTools,
			name: 'Tools',
			meta: {
				showChat: false
			}
		},
		{
			path: 'sells-reports',
			component: SellsReports,
			name: 'Sells Reports',
			meta: {
				showChat: false
			}
		},
		// {
		// 	path: 'invoices',
		// 	component: Invoice,
		// 	name: 'Invoice',
		// 	meta: {
		// 		showChat: false
		// 	}
		// },
		{
			path: 'order/:id',
			component: AdminOrder,
			name: 'Order view',
			props: true,
			meta: {
				showChat: false
			}
		},
		// {
		// 	path: 'products',
		// 	component: AdminProducts,
		// 	name: 'AdminProducts',
		// 	meta: {
		// 		showChat: false
		// 	}
		// },
		// {
		// 	path: 'product-add',
		// 	component: AdminProductsAdd,
		// 	name: 'AdminProductsAdd',
		// 	meta: {
		// 		showChat: false
		// 	}
		// },
		// {
		// 	path: 'product-edit/:title/:id',
		// 	component: ProductsEdit,
		// 	name: 'ProductsEdit',
		// 	meta: {
		// 		showChat: false
		// 	}
		// },
		{
			path: 'account/profile',
			component: AdminAccount,
			name: 'Profile1',
			children: [
				{
					path: '/admin-panel/account/profile', name: 'AdminProfile', component: AdminProfile,
				},
				{
					path: '/admin-panel/account/collaboration', name: 'Collaboration', component: Collaboration,
				},
				{
					path: '/admin-panel/account/settings', name: 'AccountSetting', component: AccountSetting,
				},
				{
					path: '/admin-panel/account/profile/:title', name: 'AdminEditProfileInfo', component: AdminEditProfileInfo,
				},
			],
		},
		{
			path: 'customer-account/:id',
			component: CustomerAdminAccount,
			name: 'CustomerAccount',
			children: [
				{
					path: '/admin-panel/customer-order-history/:id', name: 'CustomerOrderHistory', component: CustomerOrderHistory,
					meta: {
						showChat: false
					},
				},
				{
					path: '/admin-panel/customer-history/:id', name: 'CustomerHistory', component: CustomerHistory,
					meta: {
						showChat: false
					},
				},
				{
					path: '/admin-panel/customer-profile/:id', name: 'CustomerProfile', component: CustomerProfile,
					meta: {
						showChat: false
					},
				},
				{
					path: '/admin-panel/customer-action/:id', name: 'CustomerAction', component: CustomerAction,
					meta: {
						showChat: false
					},
				},
				{
					path: '/admin-panel/customer-cart-view/:id', name: 'CustomerCart', component: CustomerCart,
					meta: {
						showChat: false
					},
				},
			]
		},
		{
			path: 'privacy-policy',
			component: PrivacyPolicy,
			name: 'PrivacyPolicy1'
		},
		{
			path: 'camera',
			component: Camera,
			name: 'Camera',
			meta: {
				showChat: false
			},
			props: true
		},
		{
			path: 'scan-batch',
			component: ScanBatch,
			name: 'ScanBatch',
			meta: {
				showChat: false
			},
			props: true
		},
		{
			path: 'scan-batch-select',
			component: ScanBatchSelectCustomer,
			name: 'ScanBatchSelectCustomer',
			meta: {
				showChat: false
			},
			props: true
		},
		{
			path: 'scan-with-scanner',
			component: ScanWithScanner,
			name: 'ScanWithScanner',
			meta: {
				showChat: false
			},
			props: true
		}
	]
}

