/**
 * VueShop Global Components
 */
import HeaderV1 from './components/Layouts/Header/HeaderV1.vue';
import FixedHeader from './components/Layouts/Header/FixedHeader.vue';
import FooterV1 from './components/Layouts/Footer/FooterV1.vue';
import SocialShare from './components/Global/SocialShare';
import CurrencySign from './components/Global/CurrencySign';
import DeleteConfirmationDialog from './components/Global/DeleteConfirmationDialog';
import PageTitle from './components/Global/PageTitle';
import Sidebar from './components/Layouts/Sidebar/Sidebar';
import SidebarPanel from './components/Global/SidebarPanel';
import Timer from './components/Global/Timer';
import AppCard from 'Components/AppCard/AppCard';
import DeleteConfirmationDialog2 from './components/Global/DeleteConfirmationDialog2';
import WarningDialog from './components/Global/WarningDialog';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import LottoGrid2 from './components/Lottery/LottoGrid2.vue';
import Countdown from './components/Lottery/Countdown.vue';
import PriceDescription from './components/Lottery/PriceDescription.vue';
import TicketEditor from './components/Lottery/TicketEditor.vue';


const GlobalComponents = {
   install(Vue) {
      Vue.component('embHeaderV1', HeaderV1);
      Vue.component('embFixedHeader', FixedHeader);
      Vue.component('embFooterV1', FooterV1);
      Vue.component('embSocialShare', SocialShare);
      Vue.component('embCurrencySign', CurrencySign);
      Vue.component('embDeleteConfirmation', DeleteConfirmationDialog);
      Vue.component('embPageTitle', PageTitle);
      Vue.component('embSidebar', Sidebar);
      Vue.component('embSidebarPanel', SidebarPanel);
      Vue.component('embTimer', Timer);
      Vue.component('appCard', AppCard);
      Vue.component('embDeleteConfirmation2', DeleteConfirmationDialog2);
      Vue.component('embWarningDialog', WarningDialog);
      Vue.component('embPerfectScrollbar', VuePerfectScrollbar);
      Vue.component('embLottoGrid2', LottoGrid2);
      Vue.component('embCountdown', Countdown);
      Vue.component('embPriceDescription', PriceDescription);
      Vue.component('embTicketEditor', TicketEditor);
   }
}

export default GlobalComponents