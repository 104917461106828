<template>
  <div class="lang-menu">
    <v-menu transition="scale-transition" :nudge-width="40">
      <template v-slot:activator="{ on }">
        <v-toolbar-title v-on="on">
          <v-icon medium class="white--text">mdi-web</v-icon>
          <a
            href="javascript:void(0)"
            class="white--text font-weight-bold ml-2"
            >{{ $t("message.languageShort." + selectedLocale.locale) }}</a
          >
        </v-toolbar-title>
      </template>
      <v-list class="py-0">
        <v-list-item
          v-for="(language, key) in languages"
          :key="key"
          @click="changeLanguage(language)"
        >
          <span>{{ $t("message.language." + language.locale) }}</span>

          <v-icon v-if="selectedLocale.locale == language.locale" class="ml-2"
            >mdi-check</v-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["selectedLocale", "languages"]),
  },
  methods: {
    changeLanguage(language) {
      console.log("Selected language: ", language);
      this.$i18n.locale = language.locale;
      this.$store.dispatch("changeLanguage", language);
    },
  },
};
</script>