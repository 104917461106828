/* eslint-disable */
// Sidebar Routers
export const menus = [
   {
      path: '',
      name: "message.lotteries",
      icon: "pages",
      type: "sub_menu",
      active: true,
      children: [
         {
            name: "message.powerball",
            children_menus: null,
            path: "/lotteries/powerball"
         },
         {
            name: "message.megamillions",
            children_menus: null,
            path: "/lotteries/megamillions"
         },
      ]
   },
   {
      path: '/freeLotteries',
      name: "message.freeLotteries",
      icon: 'mdi-party-popper',
      children: null
   },
   {
      path: '/myTickets',
      name: "message.myTickets",
      icon: 'confirmation_number',
      children: null,
      logged: true,
   },
   {
      path: '/results',
      name: "message.results",
      icon: 'mdi-checkbox-marked-outline',

      children: null,
   },
   // {
   //    path: '',
   //    name: "message.results",
   //    icon: 'party_mode',
   //    type: 'mega_menu',
   //    children: {
   //       'message.men': [
   //          {
   //             path: 'T-shirt',
   //             children_menus: null,
   //             name: 'message.tShirt'
   //          },
   //          {
   //             path: 'Shirt',
   //             children_menus: null,
   //             name: 'message.shirt'
   //          },
   //          {
   //             path: 'Jeans',
   //             children_menus: null,
   //             name: 'message.jean'
   //          },
   //          {
   //             path: 'Jackets',
   //             children_menus: null,
   //             name: 'message.jackets'
   //          },
   //       ],
   //       'message.women': [
   //          {
   //             path: 'Dresses',
   //             children_menus: null,
   //             name: 'message.dresses'
   //          },
   //          {
   //             path: 'Jean',
   //             children_menus: null,
   //             name: 'message.jean'
   //          },
   //          {
   //             path: 'Shirt',
   //             children_menus: null,
   //             name: 'message.shirt'
   //          },
   //          {
   //             path: 'Jackets',
   //             children_menus: null,
   //             name: 'message.jackets'
   //          },
   //       ],
   //       'message.gadgets': [
   //          {
   //             path: 'Headphone',
   //             children_menus: null,
   //             name: 'message.headphone'
   //          },
   //          {
   //             path: 'Smartphone',
   //             children_menus: null,
   //             name: 'message.smartphone'
   //          },
   //          {
   //             path: 'Watch',
   //             children_menus: null,
   //             name: 'message.watch'
   //          },
   //          {
   //             path: 'Speaker',
   //             children_menus: null,
   //             name: 'message.speaker'
   //          },
   //       ],
   //       'message.accessories': [
   //          {
   //             path: 'Laptop',
   //             children_menus: null,
   //             name: 'message.laptopAccessories'
   //          },
   //          {
   //             path: 'Belts',
   //             children_menus: null,
   //             name: 'message.belts'
   //          },
   //          {
   //             path: 'Jewellery',
   //             children_menus: null,
   //             name: 'message.jewellery'
   //          },
   //          {
   //             path: 'Purse',
   //             children_menus: null,
   //             name: 'message.purse'
   //          }
   //       ]
   //    }
   // },
   {
      path: '',
      name: "message.support",
      icon: "mdi-comment-question",
      type: "sub_menu",
      children: [
         {
            name: 'message.faq',
            children_menus: null,
            path: '/faq'
         },
         {
            path: '/contact',
            name: "message.contactUs",
            icon: 'perm_contact_calendar',
            children: null
         },
      ]
   },
   // {
   //    path: "",
   //    name: "message.pages",
   //    icon: "pages",
   //    type: "sub_menu",
   //    admin: true,
   //    children: [
   //       {
   //          name: "message.about",
   //          children_menus: null,
   //          path: "/about"
   //       },
   //       {
   //          name: 'message.termAndCondition',
   //          children_menus: null,
   //          path: '/term-condition'
   //       },
   //       {
   //          name: 'message.privacyPolicy',
   //          children_menus: null,
   //          path: '/privacy-policy'
   //       },
   //       {
   //          name: 'message.blogDetail',
   //          children_menus: null,
   //          path: '/blog-detail/1'
   //       },
   //       {
   //          name: 'message.faq',
   //          children_menus: null,
   //          path: '/faq'
   //       },
   //       {
   //          name: 'message.404Page',
   //          children_menus: null,
   //          path: 'not-found'
   //       },
   //       {
   //          name: 'message.userProfile',
   //          children_menus: null,
   //          path: '/account/profile'
   //       },
   //       {
   //          name: 'message.session',
   //          path: '',
   //          icon: 'supervised_user_circle',
   //          type: "sub_menu",
   //          children_menus: [
   //             {
   //                name: 'message.signIn',
   //                path: '/session/signin'
   //             },
   //             {
   //                name: 'message.register',
   //                path: '/session/signup'
   //             },
   //             {
   //                name: 'message.forgotPassword',
   //                path: '/session/forgot-password'
   //             },
   //             {
   //                name: 'message.thankYou',
   //                path: '/session/thank-you'
   //             },
   //          ]
   //       },
   //    ]
   // },
   // {
   //    path: '/admin-panel/reports',
   //    name: "message.adminPanel",
   //    icon: 'perm_identity',
   //    children: null,
   //    admin: true,
   // },
   // {
   //    path: '/partner',
   //    name: "message.partnerPanel",
   //    icon: 'mdi-handshake-outline',
   //    children: null,
   //    partner: true,
   // },
   {
      path: '/admin-panel/sells-reports',
      name: "message.adminPanel",
      icon: 'perm_identity',
      children: null,
      admin: true,
   },
]


export const adminPanelMenus = [
   {
      path: '/admin-panel/sells-reports',
      name: "message.orders",
      icon: 'mdi-table-large',
      children: null,
      active: true
   },
   {
      path: '/admin-panel/stats',
      name: "message.reports",
      icon: 'poll',
      children: null,
      active: true
   },
   {
      path: '/admin-panel/search-user-info',
      name: "message.sideBar.customerInfo",
      icon: 'mdi-account-search',
      children: null,
      active: true
   },
   {
      path: '/admin-panel/admin-settings',
      name: "message.sideBar.settings",
      icon: 'mdi-cog',
      children: null,
      active: true
   },
   {
      path: '/admin-panel/admin-tools',
      name: "message.sideBar.tools",
      icon: 'mdi-tools',
      children: null,
      active: true
   },
   // {
   //    path: '/admin-panel/invoices',
   //    name: "message.invoices",
   //    icon: 'recent_actors',
   //    children: null,
   //    active: false
   // },
   // {
   //    path: '',
   //    name: "message.products",
   //    icon: 'shopping_cart',
   //    active: false,
   //    children: [
   //       {
   //          name: "message.products",
   //          path: "/admin-panel/products"
   //       },
   //       {
   //          name: "message.productAdd",
   //          path: "/admin-panel/product-add"
   //       },
   //    ]

   // },
   // {
   //    path: '/admin-panel/account/profile',
   //    name: "message.profile",
   //    icon: 'account_circle',
   //    active: false,
   //    children: null
   // },
   {
      path: '/home',
      name: "message.goToSite",
      icon: 'home',
      active: false,
      children: null
   },
   // {
   //    path: '/admin-panel/camera',
   //    name: "Camera",
   //    icon: 'camera',
   //    active: false,
   //    children: null
   // },
]