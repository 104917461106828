/**
 * App Entry File
 * Copyright 2018. All Rights Reserved
 */
/* eslint-disable */
import 'babel-polyfill'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import Nprogress from 'nprogress'
import VueI18n from 'vue-i18n'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import InstantSearch from 'vue-instantsearch'
import VueMoment from 'vue-moment'
import VueTidio from 'vue-tidio';
import App from './App.vue'

import { store } from './store/store'
import AppConfig from 'Constants/AppConfig'
import { auth } from './firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc } from "firebase/firestore";
import UtilFormatter from './services/utils_formatter'
import Utils from './services/utils'


import VueGtag from "vue-gtag";

// localisation messages
import messages from './lang'

// global components
import GlobalComponents from './globalComponents'

//router 
import router from './router'

// all css files included
import './lib/EmbryoCss'
import lang from './lang'

// Alliging Position for the toaster
const options = {
	toast: {
		position: SnotifyPosition.rightTop
	}
}

//plugins
Vue.use(VueMoment)
Vue.use(InstantSearch)
Vue.use(VueI18n)
Vue.use(Snotify, options)
Vue.use(GlobalComponents)
Vue.use(UtilFormatter)
Vue.use(Utils)


Vue.use(VueTidio, { appKey: process.env.VUE_APP_TIDIO_KEY, delay: 1000 });

Vue.use(VueGtag, {
	config: { id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID }
}, router);

// router navigation guards
router.beforeEach((to, from, next) => {
	Nprogress.start();
	next();
})

router.afterEach((to) => {
	Nprogress.done();
	setTimeout(() => {
		const contentWrapper = document.querySelector("html");
		if (contentWrapper !== null) {
			contentWrapper.scrollTop = 0;
		}

	}, 200);

	//console.log('router.afterEach: ', to);
	if (Vue.prototype.$tidioChatApi) {
		Vue.prototype.$tidioChatApi.display(to.meta.showChat);
	}

	const params = to.query;
	if (params.s != null && params.s != '') {
		// Check if the short url exists
		getDoc(doc(getFirestore(), "short_links", params.s))
			.then((docSnapshot) => {
				if (docSnapshot.exists()) {
					store.commit('setUtm', docSnapshot.data());

				}
			}).catch((error) => {
				console.error("Error getting short url:", error);
			});

	}
})

function onTidioChatApiReady() {
	Vue.prototype.$tidioChatApi.display(router.history.current.meta.showChat);
	setTidioVisitorInfo();
}

if (window.tidioChatApi) {
	window.tidioChatApi.on('ready', onTidioChatApiReady);
} else {
	document.addEventListener('tidioChat-ready', onTidioChatApiReady);
}

function setTidioVisitorInfo() {
	if (window.tidioChatApi && store.getters.getUser) {
		var info = store.getters.getUserInfo;
		tidioChatApi.setVisitorData({
			distinct_id: store.getters.getUser.uid,
			email: info.email,
			name: info.firstname + " " + info.lastname
		});
		document.tidioChatLang = info.language;
	} else {
		//console.log("Tidio not loaded");
	}
}

var firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_FIREBASE_APP_ID,
	measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
const app = initializeApp(firebaseConfig);

//auth.onAuthStateChanged(user => {
onAuthStateChanged(getAuth(), (user) => {
	//store.commit('fetchLocalStorage');
	store.dispatch('fetch_general_info');
	store.commit('setAdmin', false);
	if (user) {
		store.commit('setUser', user);
		store.dispatch('fetch_user_info', user.uid).then((info) => {
			setTidioVisitorInfo();
			var languages = store.getters.languages;
			for (var i = 0; i < languages.length; ++i) {
				if (languages[i].locale == info.language) {
					store.dispatch('changeLanguage', languages[i]);
				}
			}
			store.dispatch('changeCurrency', store.getters.currencies[info.currency]);
		});
		store.dispatch('fetch_user_private_info', user.uid);
		store.dispatch('fetch_cart_info', user.uid);
		store.dispatch('fetch_user_new_notifications', user.uid);
		if (router.currentRoute.path === '/session/signin') {
			router.push('/')
		}
		user.getIdTokenResult()
			.then((idTokenResult) => {
				// Confirm the user is an Admin.
				if (!!idTokenResult.claims.admin) {
					store.commit('setAdmin', true)
				}
			});

		// User is signed in.
	} else {
		// No user is signed in.
	}
});


// creating a instance of vue localisation module
const i18n = new VueI18n({
	locale: store.getters.selectedLocale.locale,
	messages,
})


Vue.config.productionTip = false

new Vue({
	store,
	router,
	i18n,
	vuetify,
	render: h => h(App)
}).$mount('#app')