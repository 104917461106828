export default {
    install(Vue) {
        Vue.prototype.$getNotificationIcon = function (notification) {
            if (notification.message.type == 1) {
                return "mdi-party-popper";
            } else if (notification.message.type == 2) {
                return "mdi-phone-classic";
            }
        }
        Vue.prototype.$getNotificationMessage = function (notification) {
            if (notification.message.type == 1) {
                //var amount = this.$formatMoney(notification.message.amount);
                return this.$t("message.notifications.dialogMsg.win", {
                    amount: this.$formatMoney(notification.message.amount),
                }); //`Congrationations, you win ${amount}!! Your money `;
            } else if (notification.message.type == 2) {
                return this.$t("message.notifications.dialogMsg.contact");
            }
        }
    }
}