import UserPanel from 'Container/UserPanel';

const HomeV1 = () => import('Views/HomeV1');
const Payment = () => import('Views/Payment/Payment');
const Checkout = () => import('Views/Checkout');
const FinalReceipt = () => import('Views/FinalReceipt');
// const CCFinalReceipt = () => import('Views/CCFinalReceipt');
const PlayFreeLottery = () => import('Views/PlayFreeLottery');
const ProductFreeLotteries = () => import('Views/ProductFreeLotteries');
const ProductLotteries = () => import('Views/ProductLotteries');
const MyTickets = () => import('Views/MyTickets');
const Results = () => import('Views/Results');
const Notifications = () => import('Views/Notifications');
const AboutUs = () => import('Views/AboutUs');
const TermCondiition = () => import('Views/TermsAndCondition');
const Faq = () => import('Views/Faq');
const Account = () => import('Views/UserAccount/Account.vue');
const OrderHistory = () => import('Views/UserAccount/OrderHistory.vue');
const Profile = () => import('Views/UserAccount/Profile.vue');
const Address = () => import('Views/UserAccount/Address.vue');
const Cards = () => import('Views/UserAccount/Cards.vue');
const EditProfile = () => import('Views/UserAccount/EditProfile.vue');
const EditAddress = () => import('Views/UserAccount/EditAddress.vue');
const EditProfileInfo = () => import('Views/UserAccount/EditProfileInfo.vue');
const ContactUs = () => import('Views/Contact');
const PrivacyPolicy = () => import('Views/PrivacyPolicy');
const Register = () => import('Views/Sessions/Register');
const ForgotPassword = () => import('Views/Sessions/ForgotPassword');
const Action = () => import('Views/Sessions/Action');
const SignIn = () => import('Views/Sessions/SignIn');
const Partner = () => import('Views/Partner');


export default {
	path: '/',
	component: UserPanel,
	redirect: '/home',
	children: [
		{
			path: '/home',
			component: HomeV1,
			meta: {
				header: 1,
				showChat: false
			}
		},
		{
			path: '/checkout/payment',
			component: Payment,
			meta: {
				showChat: false
			}
		},
		{
			path: '/checkout',
			component: Checkout,
			meta: {
				showChat: false
			}
		},
		{
			path: '/lotteries/:name',
			component: ProductLotteries,
			name: 'lotteries',
			meta: {
				showChat: false
			}
		},
		{
			path: '/cart/:cart_index',
			component: ProductLotteries,
			name: 'edit_cart',
			meta: {
				showChat: false
			}
		},
		{
			path: '/freelotteries',
			component: ProductFreeLotteries,
			name: 'freeLotteries',
			meta: {
				showChat: false
			}
		},
		{
			path: '/playFreeLottery/:ticket_id',
			component: PlayFreeLottery,
			name: 'playFreeLottery',
			meta: {
				showChat: false
			}
		},
		{
			path: '/myTickets',
			component: MyTickets,
			name: 'myTickets',
			meta: {
				showChat: false
			}
		},
		{
			path: '/results',
			component: Results,
			name: 'results',
			meta: {
				showChat: false
			}
		},
		{
			path: '/notifications',
			component: Notifications,
			name: 'notifications',
			meta: {
				showChat: false
			}
		},
		{
			path: '/checkout/final-receipt/:order_id',
			component: FinalReceipt,
			name: 'FinalReceipt',
			meta: {
				showChat: false
			}
		},
		// {
		// 	path: '/checkout/cc-final-receipt/:order_id',
		// 	component: CCFinalReceipt,
		// 	name: 'CCFinalReceipt',
		// 	meta: {
		// 		showChat: false
		// 	}
		// },
		{
			path: '/about',
			component: AboutUs,
			name: 'about',
			meta: {
				showChat: false
			}
		},
		{
			path: '/term-condition',
			component: TermCondiition,
			name: 'term-condition',
			meta: {
				showChat: false
			}
		},
		{
			path: '/faq',
			component: Faq,
			name: 'Faq',
			meta: {
				showChat: false
			}
		},
		{
			path: '/partner',
			component: Partner,
			name: 'Partner',
			meta: {
				showChat: false
			}
		},
		{
			path: '/account',
			component: Account,
			name: 'Account',
			children: [
				{
					path: '/account/order-history', name: 'OrderHistory', component: OrderHistory,
					meta: {
						showChat: false
					}
				},
				{
					path: '/account/profile', name: 'Profile', component: Profile,
					meta: {
						showChat: false
					}
				},
				{
					path: '/account/address', name: 'Address', component: Address,
					meta: {
						showChat: false
					}
				},
				{
					path: '/account/cards', name: 'Cards', component: Cards,
					meta: {
						showChat: false
					}
				},
				// {
				// 	path: '/account/notifications',
				// 	component: Notifications,
				// 	name: 'notifications'
				// },
				{
					path: '/account/profile/edit', name: 'EditProfile', component: EditProfile,
					meta: {
						showChat: false
					}
				},
				{
					path: '/account/profile/edit', name: 'EditAddress', component: EditAddress,
					meta: {
						showChat: false
					}
				},
				{
					path: '/account/profile/edit', name: 'EditProfileInfo', component: EditProfileInfo,
					meta: {
						showChat: false
					}
				},
			]
		},
		{
			path: '/contact',
			component: ContactUs,
			name: 'ContactUs',
			meta: {
				showChat: true
			}
		},

		{
			path: '/privacy-policy',
			component: PrivacyPolicy,
			name: 'PrivacyPolicy',
			meta: {
				showChat: false
			}
		},
		{
			path: '/session/signup',
			component: Register,
			name: 'Register',
			meta: {
				showChat: false
			}
		},
		{
			path: '/session/forgot-password',
			component: ForgotPassword,
			name: 'ForgotPassword',
			meta: {
				showChat: false
			}
		},
		{
			path: '/session/signin',
			component: SignIn,
			name: 'SignIn',
			meta: {
				showChat: false
			}
		},
		{
			path: '/session/action',
			component: Action,
			meta: {
				showChat: false
			}
		},
	]
}