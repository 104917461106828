<template>
  <div>
    <div v-if="currentTime">
      {{ days }} {{ $t("message.days") }} {{ hours | formatTime }}:{{
        minutes | formatTime
      }}:{{ seconds | formatTime }}
    </div>
    <div v-if="!currentTime">{{ $t("message.timesUp") }}</div>
  </div>
</template>

<script>
// https://dev.to/rachel_cheuk/timer-component-for-vue-js-3aad
export default {
  data() {
    return {
      currentTime: null,
    };
  },
  props: {
    deadline: {
      //   type: Date,
      required: true,
    },
    speed: {
      type: Number,
      default: 1000,
    },
  },
  components: {},
  mounted() {
    setTimeout(this.countdown, 1);
  },
  computed: {
    seconds() {
      return Math.floor((this.currentTime / 1000) % 60);
    },
    minutes() {
      return Math.floor((this.currentTime / 1000 / 60) % 60);
    },
    hours() {
      return Math.floor((this.currentTime / (1000 * 60 * 60)) % 24);
    },
    days() {
      return Math.floor(this.currentTime / (1000 * 60 * 60 * 24));
    },
  },
  filters: {
    formatTime(value) {
      if (value < 10) {
        return "0" + value;
      }
      return value;
    },
  },
  methods: {
    countdown() {
      //this.currentTime = this.deadline.toDate() - Date.parse(new Date());
      this.currentTime = this.deadline - Date.parse(new Date());
      if (this.currentTime > 0) {
        setTimeout(this.countdown, this.speed);
      } else {
        this.currentTime = null;
      }
    },
  },
};
</script>