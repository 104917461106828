var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar"},[_c('ul',{staticClass:"app-nav-list pl-0"},_vm._l((_vm.menus),function(menuItem,key){return _c('li',{key:key,staticClass:"app-nav-item"},[(
          (menuItem.admin == true && _vm.isAdmin) ||
          (menuItem.logged == true && _vm.getUser != null) ||
          (menuItem.admin == undefined && menuItem.logged == undefined)
        )?_c('router-link',{attrs:{"to":menuItem.path}},[_vm._v(_vm._s(_vm.$t(menuItem.name)))]):_vm._e(),(menuItem.type === 'sub_menu')?[(menuItem.children && menuItem.children.length > 0)?_c('ul',{staticClass:"sub-menu"},_vm._l((menuItem.children),function(subMenuItem,subMenuKey){return _c('li',{key:subMenuKey},[_c('router-link',{class:[
                subMenuItem.type === 'sub_menu'
                  ? 'menu-item-has-children'
                  : '',
              ],attrs:{"to":subMenuItem.path}},[_vm._v(_vm._s(_vm.$t(subMenuItem.name)))]),(subMenuItem.children_menus)?_c('ul',{staticClass:"sub-menu"},_vm._l((subMenuItem.children_menus),function(childrenItem,childrenKey){return _c('li',{key:childrenKey},[_c('router-link',{attrs:{"to":childrenItem.path}},[_vm._v(_vm._s(_vm.$t(childrenItem.name)))])],1)}),0):_vm._e()],1)}),0):_vm._e()]:_vm._e(),(menuItem.type === 'mega_menu')?[_c('ul',{staticClass:"sub-menu mega"},_vm._l((menuItem.children),function(megaitem,megaitemkey){return _c('li',{key:megaitemkey},[_c('a',[_vm._v(_vm._s(_vm.$t(megaitemkey)))]),_c('ul',{staticClass:"sub-menu"},_vm._l((megaitem),function(submega,submegakey){return _c('li',{key:submegakey},[_c('router-link',{attrs:{"to":{
                    name: 'Products',
                    params: { title: _vm.$t(megaitemkey) },
                    query: { category: submega.path },
                  }}},[_vm._v(_vm._s(_vm.$t(submega.name)))])],1)}),0)])}),0)]:_vm._e()],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }