<template>
  <div>
    <div :class="card_class">
      <div class="emb-card-content px-2 py-2" :style="background_color">
        <div class="emb-meta-info layout align-center justify-space-between">
          <div class="pa-4">{{ $t("message.grid") }} {{ gridIndex + 1 }}</div>
          <div class="pa-4">
            <v-btn x-small icon @click="deleteGrid(gridIndex)">
              <v-icon>clear</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="emb-meta-info">
          <div class="layout align-center justify-space-between pa-4">
            <div class="inline-block">
              <div
                class="pt-2"
                v-for="index in lottery_info.pools.length"
                :key="index"
              >
                <div style="height: 2em">
                  <span v-show="leftNumbers(index - 1) > 0"
                    >{{ $t("message.select") }}
                    {{ leftNumbers(index - 1) }}</span
                  >
                </div>
                <!-- {{ selection_text(gridInfo.pool[pool_index - 1]) }} -->
                <table :key="componentKey">
                  <tr
                    v-for="row in Math.ceil(
                      lottery_info.pools[index - 1].max /
                        lottery_info.pools[index - 1].columns
                    )"
                    :key="row"
                  >
                    <td
                      v-for="col in lottery_info.pools[index - 1].max -
                        row * lottery_info.pools[index - 1].columns >=
                      0
                        ? lottery_info.pools[index - 1].columns
                        : lottery_info.pools[index - 1].columns -
                          (row * lottery_info.pools[index - 1].columns -
                            lottery_info.pools[index - 1].max)"
                      :key="col"
                    >
                      <div
                        :class="
                          getNumbersClass(
                            index - 1,
                            getState(
                              index - 1,
                              lottery_info.pools[index - 1].columns *
                                (row - 1) +
                                col -
                                1
                            )
                          )
                        "
                        @click="
                          toggleState(
                            index - 1,
                            lottery_info.pools[index - 1].columns * (row - 1) +
                              col -
                              1
                          )
                        "
                      >
                        {{
                          lottery_info.pools[index - 1].columns * (row - 1) +
                          col
                        }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.add-border {
  border-style: solid;
  border-color: black;
}
.no-border {
  border-style: none;
}

.circle-deselected {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: white;
  width: 30px;
  height: 30px;
  color: black;
  /* border: 1px solid black;
  box-shadow: 1px 1px 1px black;
  border-radius: 50%; */
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.circle-deselected:hover {
  background: #eeeeee;
  width: 30px;
  height: 30px;
  color: black;
  border: 1px solid #eeeeee;
  box-shadow: 1px 1px 1px black;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.circle-selected {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  /* background: #053c5e; */
  width: 30px;
  height: 30px;
  color: white;
  /* border: 1px solid white; */
  box-shadow: 1px 1px 1px black;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.circle-selected:hover {
  background: #eeeeee;
  width: 30px;
  height: 30px;
  color: black;
  border: 1px solid #eeeeee;
  box-shadow: 1px 1px 1px black;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["lotteries_info"]),
  },
  data() {
    return {
      background_color: "background-color:white",
      card_class: "emb-card no-border",
      lottery_info: {},
      grids_states: {},
      componentKey: 0,
    };
  },
  watch: {
    // Parent component just need to increase this number to call this trigger
    resetTrigger: function () {
      this.initData();
      this.setBackgroundColor();
    },
  },
  props: ["ticket", "gridIndex", "deleteCallback", "resetTrigger"],
  components: {},
  methods: {
    getState(pool_index, number_index) {
      if (
        this.grids_states.pools == undefined ||
        this.grids_states.pools[pool_index] == undefined
      ) {
        return false;
      } else {
        if (
          this.grids_states.pools[pool_index].numbers == undefined ||
          this.grids_states.pools[pool_index].numbers[number_index] == undefined
        ) {
          return false;
        } else {
          return this.grids_states.pools[pool_index].numbers[number_index];
        }
      }
    },
    setBackgroundColor() {
      var color = "background-color:#F5F5F5";
      var card_class = "emb-card add-border";
      for (var i = 0; i < this.lottery_info.pools.length; ++i) {
        if (
          this.grids_states.pools == undefined ||
          this.grids_states.pools[i] == undefined ||
          this.grids_states.pools[i].numbers == undefined ||
          Object.keys(this.grids_states.pools[i].numbers).length !=
            this.lottery_info.pools[i].count
        ) {
          color = "background-color:white";
          card_class = "emb-card no-border";
          break;
        }
      }
      this.background_color = color;
      this.card_class = card_class;
    },
    setState(pool_index, number_index, state, update_ticket) {
      // Check the limit of selection
      if (
        state &&
        this.grids_states.pools != undefined &&
        this.grids_states.pools[pool_index] != undefined &&
        this.grids_states.pools[pool_index].numbers != undefined
      ) {
        if (
          Object.keys(this.grids_states.pools[pool_index].numbers).length >=
          this.lottery_info.pools[pool_index].count
        ) {
          return;
        }
      }

      if (this.grids_states.pools == undefined) {
        this.grids_states.pools = [];
      }

      if (this.grids_states.pools[pool_index] == undefined) {
        this.grids_states.pools[pool_index] = {};
      }

      if (this.grids_states.pools[pool_index].numbers == undefined) {
        this.grids_states.pools[pool_index].numbers = [];
      }

      if (state) {
        this.grids_states.pools[pool_index].numbers[number_index] = state;
      } else {
        delete this.grids_states.pools[pool_index].numbers[number_index];
      }
      if (update_ticket) {
        this.updateTicketNumbers();
      }
      this.setBackgroundColor();
    },
    getNumbersClass(pool_index, state) {
      var result_class = pool_index == 0 ? "accent " : "primary ";
      return state
        ? result_class + "circle-selected elevation-5"
        : "circle-deselected elevation-5";
    },
    toggleState(pool_index, number_index) {
      var state = this.getState(pool_index, number_index);
      this.setState(pool_index, number_index, !state, true);
      this.forceRerender();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    deleteGrid(grid_index) {
      this.deleteCallback(grid_index);
    },
    leftNumbers(pool_index) {
      if (
        this.grids_states.pools == undefined ||
        this.grids_states.pools[pool_index] == undefined ||
        this.grids_states.pools[pool_index].numbers == undefined
      ) {
        return this.lottery_info.pools[pool_index].count;
      }
      return (
        this.lottery_info.pools[pool_index].count -
        Object.keys(this.grids_states.pools[pool_index].numbers).length
      );
    },
    updateTicketNumbers() {
      var grid_info = this.ticket.grids[this.gridIndex];
      for (var pool_index in this.lottery_info.pools) {
        grid_info.pool[pool_index] = { numbers: [] };
        if (
          this.grids_states.pools[pool_index] != undefined &&
          this.grids_states.pools[pool_index].numbers != undefined
        ) {
          for (var number in this.grids_states.pools[pool_index].numbers) {
            if (number != undefined) {
              grid_info.pool[pool_index].numbers.push(parseInt(number) + 1);
            }
          }
        }
      }
    },
    initData() {
      this.lottery_info = this.lotteries_info.lotteries[this.ticket.type_name];
      this.grids_states = {};
      var grid_info = this.ticket.grids[this.gridIndex];
      for (var pool_index in grid_info.pool) {
        for (var number_index in grid_info.pool[pool_index].numbers) {
          this.setState(
            pool_index,
            grid_info.pool[pool_index].numbers[number_index] - 1,
            true,
            false
          );
        }
      }

      this.forceRerender();
    },
  },

  beforeMount() {
    this.initData();
  },
};
</script>