<template>
  <div>
    <div>
      <!-- Grid prices section-->

      <v-row
        class="cart-item-wrap"
        v-if="mode == 1 && ticket.grids != undefined"
      >
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-left
            justify-left
            white--text
            text-subtitle-1
          "
        >
          {{ ticket.grids.length }}
          {{ $t("message.productsPage.grids") }} x
          {{ $formatMoney(grid_price + grid_fee) }}
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-center
            justify-center
            white--text
            text-subtitle-1
          "
        >
          {{ $formatMoney((grid_price + grid_fee) * ticket.grids.length) }}
        </v-col>
        <v-col
          cols="2"
          sm="4"
          md="2"
          class="d-inline-flex align-center justify-center"
        >
        </v-col>
      </v-row>

      <!-- Quick picks price section -->
      <v-row
        class="cart-item-wrap"
        v-if="
          mode == 0 && ticket.quickPicks != undefined && ticket.quickPicks != 0
        "
      >
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-left
            justify-left
            white--text
            text-subtitle-1
          "
        >
          {{ ticket.quickPicks }}
          {{ $t("message.productsPage.quickpicksNumbers") }} x
          {{ $formatMoney(quickPicksPrices + quick_picks_fee) }}
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-center
            justify-center
            white--text
            text-subtitle-1
          "
        >
          {{
            $formatMoney(
              (quickPicksPrices + quick_picks_fee) * ticket.quickPicks
            )
          }}
        </v-col>
      </v-row>

      <!-- Multiplier price section -->
      <v-row
        class="cart-item-wrap"
        v-if="ticket.multiplier && mode != undefined"
      >
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-left
            justify-left
            white--text
            text-subtitle-1
          "
        >
          {{
            $t("message.productsPage.multiplier." + ticket.type_name + ".name")
          }}
          ({{ mode == 1 ? ticket.grids.length : ticket.quickPicks }}
          {{ $t("message.productsPage.combination") }} x
          {{ $formatMoney(multiplierPrice + multiplier_fee) }})
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-center
            justify-center
            white--text
            text-subtitle-1
          "
        >
          {{
            $formatMoney(
              mode == 1
                ? ticket.grids.length * (multiplierPrice + multiplier_fee)
                : ticket.quickPicks * (multiplierPrice + multiplier_fee)
            )
          }}
        </v-col>
      </v-row>

      <!-- Multiplay price section -->
      <v-row
        class="cart-item-wrap"
        v-if="ticket.multiplay > 1 && mode != undefined"
      >
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-left
            justify-left
            white--text
            text-subtitle-1
          "
        >
          {{ $t("message.productsPage.multiplayDraws") }} (x
          {{ ticket.multiplay }})
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-center
            justify-center
            white--text
            text-subtitle-1
          "
        >
          {{
            $formatMoney(
              getTicketSubTotalPrice({
                ticket: ticket,
                with_discount: false,
                mode: mode,
              })
            )
          }}
        </v-col>
      </v-row>

      <!-- Multiplay discount -->
      <v-row
        class="cart-item-wrap"
        v-if="ticket.multiplay > 1 && mode != undefined"
      >
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-left
            justify-left
            white--text
            text-subtitle-1
          "
        >
          {{ $t("message.productsPage.multiplayDiscount") }}
          ({{ getDiscount() }}%)
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="
            d-inline-flex
            align-center
            justify-center
            white--text
            text-subtitle-1
          "
        >
          {{
            $formatMoney(
              -(
                getTicketSubTotalPrice({
                  ticket: ticket,
                  fee_only: true,
                  mode: mode,
                }) *
                (getDiscount() / 100)
              )
            )
          }}
        </v-col>
      </v-row>
      <!-- <v-row
        class="cart-item-wrap"
        v-if="ticket.multiplay == 1 && mode != undefined"
      >
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="d-inline-flex align-left justify-left"
        >
          <h6 class="blue--text text--darken-1">
            {{ $t("message.productsPage.singleDiscount") }}
            ({{ getDiscount() }}% {{ $t("message.productsPage.onFees") }})
          </h6>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="d-inline-flex align-center justify-center"
        >
          <h6 class="blue--text text--darken-1">
            {{
              $formatMoney(
                -(
                  getTicketSubTotalPrice({
                    ticket: ticket,
                    fee_only: true,
                    mode: mode,
                  }) *
                  (getDiscount() / 100)
                )
              )
            }}
          </h6>
        </v-col>
      </v-row> -->
      <!-- Total section -->
      <v-row class="cart-item-wrap">
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="d-inline-flex align-left justify-left white--text text-h5"
        >
          {{ $t("message.billing.total") }}
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          class="d-inline-flex align-center justify-center white--text text-h5"
        >
          {{
            $formatMoney(
              getTicketSubTotalPrice({
                ticket: ticket,
                with_discount: true,
                mode: mode,
              })
            )
          }}
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md="8"
          class="d-inline-flex align-right justify-right"
        >
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getPrice",
      "getRawPrice",
      "getFee",
      "getTicketSubTotalPrice",
      "lotteries_info",
    ]),
  },
  data() {
    return {
      grid_price: 0,
      quickPicksPrices: 0,
      multiplierPrice: 0,
      grid_fee: 0,
      quick_picks_fee: 0,
      mutiplier_fee: 0,
    };
  },
  watch: {
    ticket: function () {
      this.grid_price = this.getRawPrice(this.ticket.type_name, "grid");
      this.quickPicksPrices = this.getRawPrice(
        this.ticket.type_name,
        "quickPick"
      );
      this.multiplierPrice = this.getRawPrice(
        this.ticket.type_name,
        "multiplier"
      );

      this.grid_fee = this.getFee(this.ticket.type_name, "grid");
      this.quick_picks_fee = this.getFee(this.ticket.type_name, "quickPick");
      this.multiplier_fee = this.getFee(this.ticket.type_name, "multiplier");
    },
  },
  props: ["ticket", "mode"],
  components: {},
  mounted() {
    this.grid_price = this.getRawPrice(this.ticket.type_name, "grid");
    this.quickPicksPrices = this.getRawPrice(
      this.ticket.type_name,
      "quickPick"
    );
    this.multiplierPrice = this.getRawPrice(
      this.ticket.type_name,
      "multiplier"
    );

    this.grid_fee = this.getFee(this.ticket.type_name, "grid");
    this.quick_picks_fee = this.getFee(this.ticket.type_name, "quickPick");
    this.multiplier_fee = this.getFee(this.ticket.type_name, "multiplier");
  },
  methods: {
    getDiscount() {
      var indexOfDiscount = this.lotteries_info.lotteries[
        this.ticket.type_name
      ].multiplay.indexOf(this.ticket.multiplay);
      return this.lotteries_info.lotteries[this.ticket.type_name].multiplaySave[
        indexOfDiscount
      ];
    },
  },
};
</script>