<template>
  <v-app>
    <div v-if="isSimulating" class="pa-2 yellow black--text d-flex align-center justify-space-between">
      <div>
        Simulating user: {{ getUserInfo.firstname + " " + getUserInfo.lastname }}
      </div>
      <div>
        <v-btn fab x-small class="primary white-text" @click="cancelSimulation">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      </div>
    </div>
    <router-view></router-view>
    <!-- <div class="rtl-layout" @click="toggleRTLLayout">
	      <a class="text-xl" href="javascript:void(0);">RTL</a>
	    </div>		 -->
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["rtlLayout", "isSimulating", "getUserInfo"]),
    mobileMenu: {
      get() {
        return this.$store.getters.sidebarOpen;
      },
      set(val) {
        this.$store.dispatch("toggleSidebar", val);
      },
    },
  },
  /**
   * Method To set the Rtl While page is opened
   */
  mounted() {
    if (this.rtlLayout) {
      this.$vuetify.rtl = this.rtlLayout;
    }
  },
  methods: {
    /**
     * Method To Toggle The RTL Layout
     */
    toggleRTLLayout() {
      this.$vuetify.rtl = !this.rtlLayout;
      this.$store.dispatch("changeRtlLayout");
    },
    cancelSimulation() {
      this.$store.dispatch("cancel_simulation");
    }
    
  },
};
</script>


