export default {
    "general": {
        "tax": 6.0
    },
    "lotteries": {
        "megamillions": {
            "type": 1,
            "image": "/static/images/Mega_Millions_Lottery_logo.svg",
            "minPlays": 3,
            "multiplay": [1, 5, 10, 25],
            "multiplaySave": [0, 20, 30, 60],
            "name": "Mega Millions",
            "minGrids": 3,
            "maxGrids": 10,
            "minQuickPicks": 3,
            "maxQuickPicks": 60,
            "maxPerTicket": 5,
            "pools": [
                {
                    "count": 5,
                    "max": 70,
                    "columns": 8,
                    "rows": 9,
                    "color": {
                        "deselected": '#FFFFFF',
                        "selected": 'primary'
                    }
                },
                {
                    "count": 1,
                    "max": 25,
                    "columns": 8,
                    "rows": 4,
                    "color": {
                        "deselected": '#FFFFFF',
                        "selected": 'accent'
                    }
                }
            ],
            "price": {
                "grid": {
                    "basePrice": 2,
                    "commission": 3
                },
                "multiplier": {
                    "basePrice": 1,
                    "commission": 1
                },
                "quickPick": {
                    "basePrice": 2,
                    "commission": 2
                }
            }
        },
        "powerball": {
            "type": 1,
            "image": "/static/images/powerball.png",
            "minPlays": 3,
            "multiplay": [1, 5, 10, 25],
            "multiplaySave": [0, 20, 30, 60],
            "name": "Powerball",
            "minGrids": 3,
            "maxGrids": 10,
            "minQuickPicks": 3,
            "maxQuickPicks": 60,
            "maxPerTicket": 5,
            "pools": [
                {
                    "count": 5,
                    "max": 69,
                    "columns": 8,
                    "rows": 9,
                    "color": {
                        "deselected": '#FFFFFF',
                        "selected": 'primary'
                    }
                },
                {
                    "count": 1,
                    "max": 26,
                    "columns": 8,
                    "rows": 4,
                    "color": {
                        "deselected": '#FFFFFF',
                        "selected": 'accent'
                    }
                }
            ],
            "price": {
                "grid": {
                    "basePrice": 2,
                    "commission": 3
                },
                "multiplier": {
                    "basePrice": 1,
                    "commission": 1
                },
                "quickPick": {
                    "basePrice": 2,
                    "commission": 2
                }
            }
        }
    },
    "price_mapping": {
        "megamillions": {
            "grids": {
                "no": {
                    1: "price_1KCzNEGKrZSsLGb3tadYx96C",
                    5: "price_1KCzOiGKrZSsLGb3vxOfBADR",
                    10: "price_1KCzQIGKrZSsLGb3uzH56L5I",
                    15: "price_1KCzRfGKrZSsLGb3skSaqZmM",
                    25: "price_1KCzTBGKrZSsLGb3W5kLYQB6",
                },
                "multiplier": {
                    1: "price_1KCzO4GKrZSsLGb3pEJpitI0",
                    5: "price_1KCzPcGKrZSsLGb31CzxKOmF",
                    10: "price_1KCzQzGKrZSsLGb3vUUmEUBf",
                    15: "price_1KCzSFGKrZSsLGb3YI5xWC9v",
                    25: "price_1KCzTxGKrZSsLGb3QpngL1G6",
                },
            },
            "quickpicks": {
                "no": {
                    1: "price_1KCyJCGKrZSsLGb3NzNOTmZF",
                    5: "price_1KCypRGKrZSsLGb3BuguuKeG",
                    10: "price_1KCyt2GKrZSsLGb3Mw1HGS8p",
                    15: "price_1KCyuYGKrZSsLGb3zMPLUYO7",
                    25: "price_1KCyvmGKrZSsLGb3zA4wZ42Q",
                },
                "multiplier": {
                    1: "price_1KCyJCGKrZSsLGb3SRWWIhdZ",
                    5: "price_1KCyrzGKrZSsLGb3MyQwCCDm",
                    10: "price_1KCyttGKrZSsLGb31oAwFhVq",
                    15: "price_1KCyv9GKrZSsLGb3EuAVPskF",
                    25: "price_1KCywtGKrZSsLGb3cWwpbPe6",
                },
            },
        },
        "powerball": {
            "grids": {
                "no": {
                    1: "price_1KCzb1GKrZSsLGb3tKIDDj66",
                    5: "price_1KCzcOGKrZSsLGb3d5ASBmDr",
                    10: "price_1KCzdvGKrZSsLGb38trXE9Wf",
                    15: "price_1KCzfSGKrZSsLGb3G8lXsLmV",
                    25: "price_1KCzh0GKrZSsLGb37Ro0Izxl",
                },
                "multiplier": {
                    1: "price_1KCzbfGKrZSsLGb3KcPp8fn8",
                    5: "price_1KCzd7GKrZSsLGb3HunjUiCC",
                    10: "price_1KCzeYGKrZSsLGb3ZM8O7S9E",
                    15: "price_1KCzgDGKrZSsLGb325Dh4IFi",
                    25: "price_1KCzhbGKrZSsLGb3uIeZ0cYU",
                },
            },
            "quickpicks": {
                "no": {
                    1: "price_1KCy8qGKrZSsLGb3oHlAcTg4",
                    5: "price_1KCydvGKrZSsLGb3qK1AAAj3",
                    10: "price_1KCyedGKrZSsLGb36ynL8gKC",
                    15: "price_1KCyfkGKrZSsLGb3c9rcLXc4",
                    25: "price_1KCygcGKrZSsLGb3kMEceoDg",
                },
                "multiplier": {
                    1: "price_1KCyU1GKrZSsLGb3mNsU4zU2",
                    5: "price_1KCyjOGKrZSsLGb3fCC7IiCP",
                    10: "price_1KCykTGKrZSsLGb3UaEgGsE9",
                    15: "price_1KCyl7GKrZSsLGb3xnpHlKdg",
                    25: "price_1KCymTGKrZSsLGb3AcesLDjJ",
                },
            },
        },
    },
}
