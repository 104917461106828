export const languages = [
   {
      name: "English",
      locale: "en"
   },
   {
      name: "French",
      locale: "fr"
   },
]
export const languages_fr = [
   {
      name: "Anglais",
      locale: "en"
   },
   {
      name: "Français",
      locale: "fr"
   },
]
export const currencies = [
   {
      id: 0,
      img: '/static/images/united-states.png',
      title: 'USD',
      symbol: "$"
   },
   // {
   //    id: 1,
   //    img: '/static/images/canada.png',
   //    title: "CAD",
   //    symbol: " $"
   // },
   // {
   //    id:2,
   //    img: '/static/images/australia.png',
   //    title:"AUD",
   //    symbol:" A$"
   // },
   // {
   //    id:3,
   //    img:"/static/images/united-kingdom.png",
   //    title:"GBP",
   //    symbol:"£"
   // },
   // {
   //    id:3,
   //    img: '/static/images/israel.png',
   //    title:"ILR",
   //    symbol:"ILR"
   // },
   // {
   //    id:4,
   //    img: '/static/images/france.png',
   //    title:"EUR",
   //    symbol:"€"
   // }
]