export default {
  install(Vue) {
    Vue.prototype.$formatMoney = function (number, nb_decimal = 2, forced_currency = null, forced_rate = null) {
      var rate = 1;
      var currency_title = "";
      if (forced_currency != null) {
        currency_title = forced_currency;
      } else {
        if (this.$store.getters.selectedCurrency == undefined) {
          return 0;
        }
        currency_title = this.$store.getters.selectedCurrency.title;
      }
      if (this.$store.getters.general_info == null) {
        return "";
      }
      if (forced_rate != null) {
        rate = forced_rate;
      } else {
        rate = this.$store.getters.general_info.currency[currency_title];
      }

      return (number * rate).toLocaleString(this.$store.getters.selectedLocale.locale, {
        minimumFractionDigits: nb_decimal,
        maximumFractionDigits: nb_decimal,
        style: "currency",
        currency: currency_title,
        currencyDisplay: "code"
      })
    }

    Vue.prototype.$formatMoneyToWord = function (number, currency, marker = "") {
      var startingMarker = "";
      var endingMarker = "";
      if (marker != "") {
        startingMarker = "<" + marker + ">";
        endingMarker = "</" + marker + ">";
      }
      return currency + (Math.abs(Number(number)) >= 1.0e+9

        ? startingMarker + Math.abs(Number(number)) / 1.0e+9 + endingMarker + " " + this.$t("message.billion")
        // Six Zeroes for Millions 
        : Math.abs(Number(number)) >= 1.0e+6

          ? startingMarker + Math.abs(Number(number)) / 1.0e+6 + endingMarker + " " + this.$t("message.million")
          // Three Zeroes for Thousands
          : Math.abs(Number(number)) >= 1.0e+3

            ? startingMarker + Math.abs(Number(number)) / 1.0e+3 + endingMarker + "K"

            : startingMarker + Math.abs(Number(number)) + endingMarker).toString();
    }

    Vue.prototype.$formatDate = function (timestamp) {
      var d = timestamp.toDate();
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      return `${da}-${mo}-${ye}`;
    }

    Vue.prototype.$formatTime = function (timestamp) {
      var d = timestamp.toDate();
      return new Intl.DateTimeFormat('default', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
      }).format(d);
    }

    Vue.prototype.$formatDateTime = function (timestamp) {
      return Vue.prototype.$formatDate(timestamp) + " " + Vue.prototype.$formatTime(timestamp);
    }

    // timestamp is Date object
    Vue.prototype.$formatDateISO8601 = function (timestamp) {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(timestamp);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(timestamp);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(timestamp);
      return `${ye}-${mo}-${da}`;
    }

    Vue.prototype.$removeHours = function (date, hours) {
      var newHours = new Date(date.getTime());
      newHours.setHours(newHours.getHours() - hours);
      return newHours;
    }

    Vue.prototype.$formatDateToYYYYMMDD = function (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    }
  }
}