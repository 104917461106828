//=======================| App Configuration |========================//
export default {
	//appLogo: '/static/images/lotto_logo_full.png',
	appLogo: '/static/images/lottoamigo_red.png',
	appLogoDark: '/static/images/lottoamigo_red.png',                        // App Logo,
	brand: 'Lotto Amigo',                                        		   // Brand Name
	//copyrightText: '© All Rights Reversed | Lotto Amigo website is operated by Black Danes LLC.',     // Copyright Text
	//theme color
	color: {
		// primary: '#b31515',
		// accent: '#102191',
		primary: '#BF0719',
		accent: '#053C5E',

		// primary: '#283593',
		// accent: '#ff5722',
	},
	algoliaApiKey: '6be0576ff61c053d5f9a3225e2a90f76'
}
