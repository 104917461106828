<template>
  <div>
    <v-menu
      transition="scale-transition"
      offset-overflow
      nudge-bottom="30"
      nudge-right="20"
      min-width="200"
      max-width="200"
      class="userblock-dropdown"
      light
    >
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" color="white">
          <v-icon large> mdi-account-circle</v-icon>
        </v-btn>

        <!-- <v-btn text v-on="on" color="white">
          {{ firstname }}
          <v-icon right dark> mdi-chevron-down </v-icon>
        </v-btn> -->
      </template>
      <v-list class="user-dropdown-list">
        <v-list-item class="grey lighten-3">
          <div class="credit-box" v-if="getUserPrivateInfo != null">
            {{ $t("message.accountPopup.yourCredit") }}
            <b>{{ $formatMoney(getUserPrivateInfo.credit) }}</b>
          </div>
        </v-list-item>
        <v-list-item
          v-on:click="on_click(userLink)"
          v-for="(userLink, key) in data"
          :key="key"
        >
          <v-icon class="mr-2">{{ userLink.icon }}</v-icon>
          <span>{{ $t(userLink.title) }}</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>
.credit-box {
  text-align: center;
  width: 100%;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["data"],
  computed: {
    ...mapGetters(["getUserInfo", "getUserPrivateInfo"]),
  },
  data() {
    return {
      firstname: "",
    };
  },
  beforeMount() {
    this.$store.subscribe((mutation) => {
      if (mutation.type == "setUserInfo") {
        this.firstname = mutation.payload.firstname;
      }
    });
    if (this.getUserInfo != null) {
      this.firstname = this.getUserInfo.firstname;
    }
  },
  methods: {
    on_click(userLink) {
      if ("path" in userLink) {
        this.$router.push({
          path: userLink.path,
        });
      } else if ("action" in userLink) {
        this.$store.dispatch(userLink.action);
      }
    },
  },
};
</script>