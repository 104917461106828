import en from './en'
import fr from './fr'
// import sp from './sp'
// import jp from './jp'
// import gr from './gr'

export default {
    en: {
        message: en
    },
    fr: {
        message: fr
    },
    // sp: {
    //     message: sp
    // },
    // jp: {
    //     message: jp
    // },
    // gr:{
    //     message: gr
    // }
}