<template>
  <v-dialog v-model="open" max-width="550" @click:outside="close">
    <v-card class="py-6 px-2">
      <h6 class="text-center mb-6">{{ message }}</h6>
      <v-card-actions class="layout justify-center">
        <v-btn color="accent" @click="close">{{
          $t("message.understand")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["session_warning"]),
  },
  props: ["warning_name"],
  data() {
    return {
      open: false,
      message: "",
    };
  },
  mounted() {
    this.message = this.$t("message.session_warning." + this.warning_name);
  },
  methods: {
    openDialog() {
      this.open = true;
    },
    close() {
      this.open = false;
      this.session_warning[this.warning_name].status = true;
    },
  },
};
</script>

