// *-QP-3-MP-5-M-Y
exports.convert_ticket_to_code = (ticket) => {
    var result = ticket.type_name + "-";
    if (ticket.quickPicks != undefined) {
        result += "QP-" + ticket.quickPicks.toString();
    } else {
        result += "GRIDS-" + ticket.grids.length.toString();
    }
    result += "-MP-" + ticket.multiplay.toString()
    result += "-M-" + (ticket.multiplier ? "Y" : "N");
    return result;
}
// Compare if a product like megamillions-QP-3-MP-5-M-Y is include in the pattern like *-QP-3-MP-5-M-Y
exports.compare_product_pattern = (pattern, product_code) => {
    var pattern_parts = pattern.split("-");
    var product_parts = product_code.split("-");
    var product_count = 1;
    if (pattern_parts.length != product_parts.length) {
        return [false, product_count];
    }
    for (var i = 0; i < pattern_parts.length; i++) {
        if (i == 2 && pattern_parts[i] != "*") {
            var input_product_count = parseInt(product_parts[i]);
            var pattern_product_count = parseInt(pattern_parts[i]);
            if (input_product_count % pattern_product_count == 0) {
                product_count = input_product_count / pattern_product_count;
            }
        }
        else if (pattern_parts[i] != "*" && pattern_parts[i] != product_parts[i]) {
            return [false, product_count];
        }
    }
    return [true, product_count];
}
exports.convert_currency = (general_info, from, to, price) => {
    if (from == to) {
        return price;
    }
    if (from == "USD") {
        return price * general_info.currency["CAD"]
    } else {
        return price / general_info.currency["CAD"]

    }
}
