<template>
  <div class="page-title-bar">
    <div class="container">
      <h3 class="mb-4 white--text">{{ heading }}</h3>
      <p class="white--text font-weight-regular">{{ subHeading }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["heading", "subHeading"],
};
</script>